    <app-header2></app-header2>
<!-- Banner sec -->



<section class="banner-slider">
    <div class="">
        <div class="banner-wrapper">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-11">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 style="font-size: 50px;" class="text-custom-white">{{'MODOS DE ADQUISICIÓN DE UNA NERVA'|translate}}</h2>
                            </div>
                        </div>
                    </div>
                </div>

        </div>

    </div>
</section>

<section class="news-sec-desktop d-md-block " style="background: white;">
    <div class="container-fluid  p-relative shadow-top ">

        <div class="row ml-1 mt-5">
            <div class="col-md-2"></div>
            <div *ngIf="lang !== 'fr'" class="col-md-4" style="text-align: start;">
                <h4>{{'Adquiere una Nerva de la manera que mejor se adapte a ti.'|translate}}</h4>
            </div>
            <div class="col-md-4" style="text-align: start;">
                <ng-container  *ngIf="language !== 'fr'">

                    <h6 style="color: rgb(0, 162, 255);">{{'PLAN'|translate}} 1</h6>
                    <h3>{{'Renting Nerva EXE'|translate}}:</h3>
                    <hr color="black">
                        <p style="text-align: start;">{{'Alquila Nerva EXE sin entrada desde 178,45€/mes con seguro a todo riesgo, mantenimiento y neumáticos incluidos (6000 km/año)'|translate}}</p>
                        <ul *ngIf="lang == 'fr'">
                            <li>{{'900€ de bonus écologique sans mise à la casse Prix final 3 430€ TTC.'|translate}}</li>
                            <li>{{'Prime de mise à la casse en fonction des revenus (100 à 1100€).'|translate}}</li>
                            <li><a href="https://rentall-partners.com/nerva" target="_blank" style="color: rgb(100, 100, 100);">{{'Descubre más información'|translate}} <span style="color: rgb(0, 162, 255);">></span> </a></li>
                        </ul><br>
                        <p *ngIf="lang == 'fr'" style="text-align: start;">{{'Louez les batteries pour 39,90€ par mois pendant 5 ans. Après cette période, si vous renouvelez la location, nous vous donnerons de nouvelles batteries. Les batteries usagées sont destinées à des projets humanitaires.'|translate}}</p>

                        <a *ngIf="language !== 'fr'" href="https://rentall-partners.com/nerva" target="_blank" style="color: rgb(100, 100, 100);">{{'Descubre más información'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br><br>
                        <p *ngIf="language == 'it'" style="text-align: start;">{{'Noleggiate le batterie a 39,90 € al mese per 5 anni. Dopo questo periodo, se rinnoverà il contratto di noleggio, vi forniremo delle nuove batterie. Le batterie usate saranno destinate a progetti umanitari.'|translate}}</p>
                        <a  href="/enomia-circular" target="_blank" style="color: rgb(100, 100, 100);">{{'Nuestra economia circular'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br><br>

                        <a href="https://nerva.eco/concesionario" style="color: rgb(100, 100, 100);">{{'Consulta condiciones en tu concesionario'|translate}} <span style="color: rgb(0, 162, 255);">></span></a>
                            <div class="mt-5" style="text-align: start;">
                                <h6 style="color: rgb(0, 162, 255);">{{'PLAN'|translate}} 2</h6>
                                <h3>{{'Compra la parte del ciclo y alquila las baterías de Nerva EXE'|translate}}:</h3>
                                <hr color="black">
                                    <p style="text-align: start;">{{'Realiza un desembolso inicial de 4480€ para la adquisición de la parte ciclo (la parte ciclo es todo el vehículo menos las baterías).'|translate}}</p>
                                    <ul *ngIf="language !== 'it'">
                                        <li style="list-style: disc!important"><p style="text-align: start;">{{'Financiación de 1100€ Plan Moves III sin achatarramiento Precio final 3.380 €'|translate}}</p></li>
                                        <li style="list-style: disc!important"><p style="text-align: start;">{{'Financiación de 1300€ Plan Moves III con achatarramiento - Precio final 3.180 €'|translate}}</p></li>
                                    </ul>
                                    <a *ngIf="language !== 'it' && language !== 'fr'"  href="https://www.idae.es/ayudas-y-financiacion/para-movilidad-y-vehiculos/programa-moves-iii" target="_blank" style="color: rgb(100, 100, 100);">{{'Descubre más información'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br><br>
                                    <p *ngIf="language !== 'it' && language !== 'fr'" style="text-align: start;">{{'Alquila las baterías por 39,90€ al mes durante 5 años. Pasado este periodo si renuevas el renting, te ponemos unas baterías nuevas. Las baterías usadas se destinarán a proyectos humanitarios.'|translate}}</p>
                                    <a href="https://nerva.eco/economiaCircular" style="color: rgb(100, 100, 100);">{{'Nuestra economia circular'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br>
                                    <a href="https://nerva.eco/concesionario" style="color: rgb(100, 100, 100);">{{'Consulta condiciones en tu concesionario'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br><br>
                            </div>
                            </ng-container>
                    <div *ngIf="language !== 'it'" class="mt-5 mb-5" style="text-align: start;">
                        <h6 style="color: rgb(0, 162, 255);">{{'PLAN'|translate}} 3</h6>
                        <h3>{{'Compra una Nerva'|translate}}:</h3>
                        <hr color="black">
                            <p style="text-align: start;">{{'Adquiere una Nerva completa por 6930€ (IVA incluido).'|translate}}</p>
                            <ul>
                                <li style="list-style: disc!important"><p style="text-align: start;">{{'Financiación de Plan Moves IllI sin achatarramiento'|translate}}</p></li>
                                <li style="list-style: disc!important"><p style="text-align: start;">{{'Financiación de Plan Moves III con achatarramiento'|translate}}</p></li>
                            </ul>
                            <a href="https://nerva.eco/economiaCircular" target="_blank" style="color: rgb(100, 100, 100);">{{'Nuestra economia circular'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br>
                            <a href="https://nerva.eco/concesionario" style="color: rgb(100, 100, 100);">{{'Consulta condiciones en tu concesionario'|translate}} <span style="color: rgb(0, 162, 255);">></span></a><br><br>
                    </div>
            </div>
            <div class="col-md-2 mt-5 mb-5"></div>
        </div>
    </div>
</section>
<app-footer2></app-footer2>


